import React from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, Link }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Hr from '../components/Hr'
import H4 from '../components/H4'
import H5 from '../components/H5'
import Paragraph from '../components/Paragraph'

import { Container } from '../components/styles/Layout.styles'
import { btnLarge } from '../components/styles/OutlineButton.styles'
import { 
  styledWebsiteHosting,
  header,
  content
} from '../components/styles/WebsiteHosting.styles'
import { termosBox } from '../components/styles/Contact.styles'
import HospedagemDeSitesForm from '../components/ContactForm2'

const fields = [
  {
    label: 'Nome Completo',
    name: 'your-name',
    type: 'text',
    required: true
  },
  {
    label: 'Email',
    name: 'your-email',
    type: 'email',
    required: true
  },
  {
    label: 'Telefone',
    name: 'your-phone',
    type: 'text',
    required: true,
    mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  },
  {
    label: 'Assunto',
    name: 'your-subject',
    type: 'text',
    required: true
  },
  {
    label: 'Departamento',
    name: 'department',
    type: 'select',
    options: [
      { value: 1, label: 'Suporte' },
      { value: 2, label: 'Financeiro' },
      { value: 3, label: 'Vendas' }
    ],
    required: true
  },
  {
    label: 'Mensagem',
    name: 'your-message',
    type: 'text',
    multiline: true,
    rows: 6,
    required: true
  },
  {
    label: (
      <span css={termosBox}>
        Ao continuar, indica que aceita os
        {" "}
        <Link to="/termos-de-servico" style={{ textDecoration: 'underline' }}>
          Termos de Serviço
        </Link>
        {" "}
        e a nossa
        {" "}
        <Link to="/politica-de-privacidade" style={{ textDecoration: 'underline' }}>
          Política de Privacidade.
        </Link>
      </span>
    ),
    name: 'your-consent',
    type: 'checkbox',
    required: true
  },
]

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section css={styledWebsiteHosting}>
      <Container>
        <Row
          flexDirection={['column', 'row']}
          css={header}
        >
          <Column
            width={[1]}
            mb={50}
          >
            <H4>
              <span>Hospedagem de Sites</span>
              Que tal um parceiro?
            </H4>
            <Paragraph>Adicionando um serviço de armazenamento remoto para o seu serviço de hosting, seus dados serão armazenados em um local remoto seguro em nosso data center, ajudando a maximizar a redundância e melhorando o desempenho global de seu servidor atual.</Paragraph>
            <Paragraph>A HostDime não mais comercializa planos de hospedagem de sites e revenda de hospedagem, no entanto temos clientes parceiros que oferecem esses e outros serviços.</Paragraph>
            <div style={{ textAlign: 'center', marginTop: 30 }}>
              <a
                href='https://www.hostdime.com.br/parceiros.php'
                target='_blank'
                css={btnLarge}
              >
                IR PARA NOSSO PARCEIRO
              </a>
            </div>
          </Column>
        </Row>
        <Hr
          lineColor='lightPeriwinkle'
          maxWidth={[328, '100%', 680, 680]}
          style={{
            opacity: 0.5,
            margin: '0 auto'
          }}
        />
        <Row
          flexDirection={['column']}
          css={content}
          mt={40}
        >
          <Column
            width={[1]}
          > 
            <H5>Se você já é nosso cliente, tem mais de 5 servidores dedicados e quer entrar na lista, nos envie sua solicitação.</H5>
            <div style={{ textAlign: 'center', marginTop: 30 }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <HospedagemDeSitesForm
                  fields={fields}
                  formId={34}
                  submitText='Enviar'
                />
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo 
        title='Hospedagem de sites | Hospede seu site'
        description='Garanta mais segurança ao seu site, hospede seu endereço web em um dos nossos parceiros. Confira hospedagens de confiança!'
      />
      <PageTemplate
        title='Hospedagem de Sites'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
